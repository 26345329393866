html,
body {
  overflow-x: hidden;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

$breakpoints: (
  xs: 576px,
  sm: 768px,
  md: 992px,
  lg: 1200px,
);

// HEADER

.header-gradient {
  // background-image: linear-gradient(-60deg, #335c04 0%, #429e51 100%);
  background-image: linear-gradient(-60deg, #01350a 0%, $hijo1 100%);
}

.bungkus {
  position: relative;
}

.custom-shape-divider-bottom-1622446686 {
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-bottom-1622446686 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  // height: 84px;
  height: 99px;
}

.custom-shape-divider-bottom-1622446686 .shape-fill {
  fill: #ffffff;
}

.custom-shape-divider-bottom-1622774301 {
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1622774301 svg {
  position: relative;
  display: block;
  width: calc(138% + 1.3px);
  height: 112px;
}

.custom-shape-divider-bottom-1622774301 .shape-fill {
  fill: #ffffff;
}

.miring {
  transform: skewY(4.5deg) scaleY(1.5) translateY(15%);
  transform-origin: 0 50%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.subtitle {
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  letter-spacing: 0.12em;
  text-transform: uppercase;
}

.navbar-brand svg {
  width: 150px;
  height: 100px;
  max-height: 85px;
}

.navbar-brand {
  padding: 0.5em 0 1em 0;
}

.navbar-brand img {
  max-height: 57px;
}

.svg-logosize {
  width: 700px;
  height: 400px;
}

.nav-navbar .nav-link,
.nav-btn {
  // @media screen and (max-width: ($breakpoint-lg - 1px)) {
  //   font-size: 0.77em;
  // }

  font-size: 1em;
}

.backdrop-navbar {
  background-color: rgba(126, 126, 126, 0.78);
}

@media screen and (max-width: ($breakpoint-sm - 1px)) {
  .navbar-expand-lg .navbar-mobile {
    width: 54%;
  }
}

.btn-demo {
  font-size: 1.05em;
}

.jumbo-y-padding {
  // padding: 60px 0 35px;
  overflow: visible;
}

.laptop-smoll {
  position: absolute !important;
  min-height: 480px !important;
  max-height: 550px !important;
  left: 0 !important;
  z-index: 10 !important;
  filter: drop-shadow(10px 20px 20px rgba(0, 0, 0, 0.1))
    drop-shadow(10px 4px 4px rgba(0, 0, 0, 0.15)) !important;
  object-fit: contain !important;
  object-position: left !important;
  box-shadow: none !important;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  border-radius: 0%;
  -webkit-border-radius: 0%;
  -moz-border-radius: 0%;
  max-width: initial !important;
  min-width: 760px !important;
  width: 154% !important;
}

.laptop {
  position: absolute !important;
  top: 2rem;
  left: 0 !important;
  z-index: 10 !important;
  filter: drop-shadow(10px 20px 20px rgba(0, 0, 0, 0.1))
    drop-shadow(10px 4px 4px rgba(0, 0, 0, 0.15)) !important;
  max-width: initial !important;
  min-width: 1000px !important;
  width: 200% !important;
  min-height: 360px !important;
  max-height: 530px !important;
  object-fit: contain !important;
  object-position: left !important;
  box-shadow: none !important;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  border-radius: 0;
  -webkit-border-radius: 0%;
  -moz-border-radius: 0%;
}

.judul {
  margin: 0.7em 0 0.7em 0;
  line-height: 80%;

  @media screen and (max-width: ($breakpoint-sm - 1px)) {
    height: 50px;
  }
}

span.min-underline {
  // padding: 0 20px 1px 0;
  background-repeat: repeat-x;
  background-size: 100% 10px;
  background-position: 0 86%;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#def2d8),
    to(#def2d8)
  );
  background-image: linear-gradient(to right, #b9e9e9 0%, #b9e9e9 100%);
}

// span.with-underline {
//   -webkit-box-shadow: 0 -0.8ch 0 #abe8e2 inset;
//   box-shadow: inset 0 -0.8ch 0 #b9e9e9;
//   -webkit-transition: all 0.2s cubic-bezier(0.5, 0.01, 0.5, 1);
//   transition: all 0.2s cubic-bezier(0.5, 0.01, 0.5, 1);
// }

// ====Partner
.partner-gap {
  gap: 1.5rem 0;
}

// ====footer
.logo-footer {
  max-height: 90px;
}

.svg-logosize-footer {
  height: 400px;
}

#footer {
  & h6 {
    font-size: 1.225em;
    font-weight: 900;
  }
  &.text-white {
    .nav-link {
      color: #fff !important;
      font-weight: 300;
      // line-height: 1.5em;

      &:hover {
        color: $hijo1 !important;
      }
    }
  }
}

.shadow-vid {
  box-shadow: 50px 50px 2.8px 2.2px rgba(0, 0, 0, 0.034),
    50px 50px 6.7px 5.3px rgba(0, 0, 0, 0.048),
    50px 50px 12.5px 10px rgba(0, 0, 0, 0.06),
    50px 50px 22.3px 17.9px rgba(0, 0, 0, 0.072),
    50px 50px 41.8px 33.4px rgba(0, 0, 0, 0.086), 50px 50px 0 $hijo2;

  @media screen and (max-width: ($breakpoint-md)) {
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
      0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
      0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086);
  }
}

.custom-shape-divider-bottom-1622773581 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1622773581 svg {
  position: relative;
  display: block;
  width: calc(138% + 1.3px);
  height: 113px;
}

.custom-shape-divider-bottom-1622773581 .shape-fill {
  fill: #ffffff;
}

.card-body {
  font-size: 1.2em;
}

.card-title {
  font-weight: 600;
}

.text-bitmedium {
  font-size: 1.133em;
}

.et-height {
  line-height: 1em;
}

.btn-outwhite {
  border-color: #fff;
}

.g-recaptcha {
  // transform: scale(0.5);
  // -webkit-transform: scale(0.5);
  // transform-origin: 0 0;
  // -webkit-transform-origin: 0 0;

  margin-bottom: 1em;
  transform: scale(1.15);
  transform-origin: 0 0;

  // @media screen and (max-width: ($breakpoint-lg - 1px)) {
  //   transform: scale(0.96);
  // }
}

.row .col-price {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  /* 
	In the order, equal to
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: 300px;
	*/
  margin: 0.5em 1em;
}

.row .col-price .btn {
  align-self: center;
  margin-top: auto;
}

/**
 * Under 900px wrap cols
 */
@media (max-width: 900px) {
  .row-price {
    flex-wrap: wrap;
  }
}

.lozad,
.lozad-fitur {
  opacity: 0;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

/*==== FADE IN UP ===*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUp {
  opacity: 1;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@media screen and (min-width: ($breakpoint-lg)) {
  .nav-link.is-slide {
    position: relative;
  }
  
  .nav-link.is-slide:hover::after {
    -webkit-transform-origin: left center;
    transform-origin: left bottom;
    -webkit-transform: scale(1,1);
    transform: scale(1,1);
  }
  
  .nav-link.is-slide::after{
    content: "";
    position: absolute;
    bottom: 11px;
    right: 0px;
    width: 99%;
    -webkit-transform-origin: right center;
    transform-origin: right center;
    height: 3px;
    border-radius: 50px;
    background: $hijo1;
    -webkit-transform: scale(0,1);
    transform: scale(0,1);
    -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.4,0,0.2,1);
    transition: -webkit-transform 0.4s cubic-bezier(0.4,0,0.2,1);
    transition: transform 0.4s cubic-bezier(0.4,0,0.2,1);
    transition: transform 0.4s cubic-bezier(0.4,0,0.2,1),-webkit-transform 0.4s cubic-bezier(0.4,0,0.2,1);
    transition: transform 0.4s cubic-bezier(0.4,0,0.2,1),-webkit-transform 0.4s cubic-bezier(0.4,0,0.2,1);
  }
}