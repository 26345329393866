// Theme
//
// Change the default theme color, fonts, sizing, etc. by modifying
// the following variables.

// Google fonts
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700|Nunito+Sans:200,400,600,700,800&display=swap");
// @import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700|Lato:wght@300;400;700;900");

//----------------------------------------
// Color system
//----------------------------------------
$blue: #50a1ff;
$indigo: #6610f2;
$purple: #926dde;
$pink: #e83e8c;
$red: #ff4954;
$orange: #ffbe00;
$yellow: #ffba00;
$green: #15c235;
$teal: #20c997;
$cyan: #17a2b8;

// custom color
$hijo1: hsl(138, 97%, 38%);
$hijo2: hsl(138, 47%, 38%);
$gray-100: hsl(0, 0%, 98%) !default;
$gray-200: hsl(0, 0%, 92%) !default; // light gray
$gray-300: hsl(0, 0%, 89%) !default;
$gray-400: hsl(0, 0%, 83%) !default;
$gray-500: hsl(0, 0%, 66%) !default; // gray product
$gray-600: hsl(0, 0%, 49%) !default;
$gray-700: hsl(0, 0%, 31%) !default;
$gray-800: hsl(0, 0%, 23%) !default;
$gray-900: hsl(0, 0%, 13%) !default;

// Context colors
$color-primary: $blue;
$color-secondary: #e9ecf0;
$color-success: $green;
$color-info: $purple;
$color-warning: $yellow;
$color-danger: $red;
$color-light: #f8f9fa;
$color-dark: #191919;

//----------------------------------------
// Background colors
//----------------------------------------
$color-bg-lightest: #fcfdfe;
$color-bg-lighter: #f9fafb;
$color-bg-light: #f5f6f7;
$color-bg-body: #ffffff;
$color-bg-dark: #191919;
$color-bg-gray: #fafbfb;

//----------------------------------------
// Text colors
//----------------------------------------
$color-text-darker: #323d47;
$color-text-dark: #555555;
$color-text: #757575;
$color-text-light: #999999;
$color-text-lighter: #bfc5ca;
$color-text-lightest: #d3d3d3;
$color-text-secondary: #929daf;
$color-text-disable: #a5b3c7;
$color-text-placeholder: #c9ccce;
$color-title: $color-text-darker;
$color-subtitle: $color-text-light;

//----------------------------------------
// Fonts
//----------------------------------------
$fonts-dir: "../fonts/";
$font-family-base: "Open Sans", sans-serif;
$font-family-title: "Nunito Sans", sans-serif;

$font-size-base: 0.9375rem;

$font-weight-base: 300;
$line-height-base: 1.9;

$h1-font-size: $font-size-base * 2.75;
$h2-font-size: $font-size-base * 2.25;
$h3-font-size: $font-size-base * 1.875;
$h4-font-size: $font-size-base * 1.625;
$h5-font-size: $font-size-base * 1.3125;
$h6-font-size: $font-size-base * 1.125;

$h1-font-weight: 600;
$h2-font-weight: 600;
$h3-font-weight: 600;
$h4-font-weight: 400;
$h5-font-weight: 400;
$h6-font-weight: 400;

$display1-size: 5rem;
$display2-size: 4rem;
$display3-size: 3.5rem;
$display4-size: 3rem;

$display1-weight: 200;
$display2-weight: 200;
$display3-weight: 200;
$display4-weight: 200;

//----------------------------------------
// Navbar
//----------------------------------------
$navbar-min-height: 56px;
$navbar-offset-top: 10px;

//----------------------------------------
// Other variables
//----------------------------------------

// Feel free to override other variables here. You can see the list of
// all the available variables in `/plugin/thesaas/scss/_variables.scss`
